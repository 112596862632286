import {
  Component,
  OnInit,
  NgModule,
  Inject,
  Pipe,
  PipeTransform,
  ElementRef,
} from "@angular/core";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogConfig as MatDialogConfig,
} from "@angular/material/legacy-dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { CommonService } from "../../common/services/common-services";
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
// import { UniquePipe } from '../../common/services/unique';
// import { debug } from 'util';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthenticationComponent } from "./../../common/authentication/authentication.component";
import { DataSharingService } from "./../../common/services/data-sharing.service";

import * as _moment from "moment";
// import moment = require('moment');
import * as moment from "moment";
import { min } from "rxjs/operators";
import { Loader } from "../../common/services/loader";
import { GlobalConstants } from "../../common/services/global-variables";
// import {default as _rollupMoment} from 'moment';

// const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

declare var $: any;

export interface DialogData {}

@Component({
  selector: "app-airport-transfers",
  templateUrl: "./airport-transfers.component.html",
  styleUrls: ["./airport-transfers.component.scss"],
  providers: [CommonService],
})
export class AirportTransfersComponent implements OnInit {
  currentUser: any;
  userData: any;
  airportList: any = [
    {
      city: "Agra",
      airport: "Agra Airport",
      luxury: true,
    },
    {
      city: "Ahmedabad",
      airport: "Sardar Vallabhbhai Patel International Airport",
      luxury: true,
    },
    {
      city: "Amritsar",
      airport: "Sri Guru Ram Dass Jee International Airport",
      luxury: true,
    },
    // { city: "Aurangabad", airport: "Aurangabad Airport", luxury: false },
    {
      city: "Bangalore",
      airport: "Kempegowda International Airport",
      luxury: true,
    },
    // { city: "Baroda", airport: "Airport Harni ", luxury: false },
    // { city: "Bhopal", airport: "Raja Bhoj Airport", luxury: false },
    {
      city: "Bhubaneswar",
      airport: "Biju Patnaik International Airport",
      luxury: true,
    },
    {
      city: "Chandigarh",
      airport: "Chandigarh International Airport",
      luxury: true,
    },
    { city: "Chennai", airport: "Chennai International Airport", luxury: true },
    // { city: "Cochin", airport: "Cochin International Airport", luxury: false },
    {
      city: "Coimbatore",
      airport: "Coimbatore International Airport",
      luxury: false,
    },
    { city: "Dehradun", airport: "Jolly Grant Airport", luxury: false },
    // {
    //   city: "Delhi/NCR",
    //   airport: "Indira Gandhi International Airport",
    //   luxury: true,
    // },
    // {
    //   city: "Guwahati",
    //   airport: "Lokpriya Gopinath Bordoloi International Airport",
    //   luxury: false,
    // },
    // { city: "Gwalior", airport: "The Gwalior Airport", luxury: false },
    {
      city: "Hyderabad",
      airport: "Rajiv Gandhi International Airport ",
      luxury: true,
    },
    // {
    //   city: "Indore",
    //   airport: "Devi Ahilya Bai Holkar Airport",
    //   luxury: false,
    // },
    { city: "Jaipur", airport: "Jaipur International Airport ", luxury: true },
    { city: "Jammu", airport: "Jammu Airport", luxury: false },
    // { city: "Jamshedpur", airport: "Sonari Airport", luxury: false },
    { city: "Jodhpur", airport: "Jodhpur Airport", luxury: true },
    { city: "Kochi", airport: "Cochin International Airport", luxury: true },
    {
      city: "Kolkata",
      airport: "Netaji Subhas Chandra Bose International Airport",
      luxury: true,
    },
    // { city: "Leh", airport: "Kushok Bakula Rimpochee Airport", luxury: false },
    {
      city: "Lucknow",
      airport: "Chaudhary Charan Singh International Airport",
      luxury: false,
    },
    {
      city: "Madurai",
      airport: "Madurai International Airport",
      luxury: false,
    },
    // {
    //   city: "Mangalore",
    //   airport: "Mangalore International Airport ",
    //   luxury: false,
    // },
    {
      city: "Mumbai",
      airport: "Chhatrapati Shivaji Maharaj International Airport",
      luxury: true,
    },
    { city: "Mysore", airport: "Mysore Airport", luxury: false },
    // {
    //   city: "Nagpur",
    //   airport: "Dr. Babasaheb Ambedkar International Airport",
    //   luxury: false,
    // },
    // { city: "Nasik", airport: "Nashik International Airport", luxury: false },
    {
      city: "New Delhi",
      airport: "Indira Gandhi International Airport",
      luxury: true,
    },
    { city: "Patna", airport: "Lok Nayak Jayaprakash Airport", luxury: false },
    // { city: "Pondicherry", airport: "Puducherry Airport ", luxury: false },
    { city: "Pune", airport: "Pune International Airport", luxury: true },
    // { city: "Raipur", airport: "Swami Vivekananda Airport", luxury: false },
    // { city: "Ranchi", airport: "Birsa Munda Airport.", luxury: false },
    // { city: "Rishikesh", airport: "Jolly Grant airport ", luxury: false },
    // { city: "Shimla", airport: "Shimla Airport", luxury: false },
    // { city: "Silliguri", airport: "Bagdogra Airport", luxury: false },
    // {
    //   city: "Srinagar",
    //   airport: "Sheikh ul-Alam International Airport",
    //   luxury: false,
    // },
    // {
    //   city: "Trichy",
    //   airport: "Tiruchirappalli International Airport",
    //   luxury: false,
    // },
    // {
    //   city: "Trivandrum",
    //   airport: "Trivandrum International Airport",
    //   luxury: false,
    // },
    { city: "Udaipur", airport: "Maharana Pratap Airport", luxury: true },
    { city: "Vadodara", airport: "Vadodara Airport", luxury: true },
    { city: "Varanasi", airport: "Lal Bahadur Shastri Airport", luxury: false },
    // {
    //   city: "Vijayawada",
    //   airport: "Vijayawada International Airport",
    //   luxury: false,
    // },
    { city: "Vishakhapatnam", airport: "Visakhapatnam Airport", luxury: true },
  ];
  // oldAirportList: any = [
  //   {
  //     city: 'Ahmedabad',
  //     airport: 'Sardar Vallabhbhai Patel International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["T1-Domestic", "T2-International"]
  //   },
  //   {
  //     city: 'Amritsar',
  //     airport: 'Sri Guru Ram Dass Jee International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Bangalore',
  //     airport: 'Kempegowda Bangalore International Airport',
  //     buggy_service: 'Y',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["T1-Domestic", "T1-International"]
  //   },
  //   {
  //     city: 'Bhubaneshwar',
  //     airport: 'Biju Patnaik Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Chandigarh',
  //     airport: 'Chandigarh International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["T1-Domestic", "T1-International"]
  //   },
  //   {
  //     city: 'Chennai',
  //     airport: 'Chennai International Airport',
  //     buggy_service: 'Y',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["T1-Domestic", "T2-Domestic", "T3-International", "T4-International"]
  //   },
  //   {
  //     city: 'Cochin',
  //     airport: 'Cochin International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["T1-Domestic", "T3 International"]
  //   },
  //   {
  //     city: 'Dehradun',
  //     airport: 'Jolly Grant Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Delhi',
  //     airport: 'Indira Gandhi International Airport',
  //     buggy_service: 'Y',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["T1, T2, T3-Domestic", "T3-International"]
  //   },
  //   {
  //     city: 'GOA',
  //     airport: 'Dabolim International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", "International"]
  //   },
  //   {
  //     city: 'Guwahati',
  //     airport: 'Lokpriya Gopinath Bordoloi International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Hyderabad',
  //     airport: 'Rajiv Gandhi International Airport',
  //     buggy_service: 'Y',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic ", "International"]
  //   },
  //   {
  //     city: 'Imphal',
  //     airport: 'Bir Tikendrajit International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Indore',
  //     airport: 'Devi Ahilya Bai Holkar International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Jaipur',
  //     airport: 'Jaipur International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["International", ""]
  //   },
  //   {
  //     city: 'Jaiselmer',
  //     airport: 'Jaisalmer Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Jammu',
  //     airport: 'Satwari Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Jodhpur',
  //     airport: 'Jodhpur Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Leh ',
  //     airport: 'Kushok Bakula Rimpochee Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Kolkata',
  //     airport: 'Netaji Subhash Chandra Bose International Airport',
  //     buggy_service: 'Y',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", "International"]
  //   },
  //   {
  //     city: 'Lucknow',
  //     airport: 'Chaudhary Charan Singh International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Mangalore',
  //     airport: 'Mangalore International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Mumbai',
  //     airport: 'Chhatrapati Shivaji Maharaj International Airport',
  //     buggy_service: 'Y',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["T1, T2-Domestic", "T2-International"]
  //   },
  //   {
  //     city: 'Nagpur',
  //     airport: 'Dr. Babasaheb Ambedkar Airport',
  //     buggy_service: 'Y',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", "International"]
  //   },
  //   {
  //     city: 'Pune',
  //     airport: 'Pune International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", "International"]
  //   },
  //   {
  //     city: 'Trivandrum',
  //     airport: 'Trivandrum International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["International", ""]
  //   },
  //   {
  //     city: 'Udaipur',
  //     airport: 'Maharana Pratap Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Vadodara',
  //     airport: 'Vadodara Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   },
  //   {
  //     city: 'Varanasi',
  //     airport: 'Lal Bahadur Shastri International Airport',
  //     buggy_service: 'N',
  //     departure: 'Y',
  //     arrival: 'Y',
  //     terminal: ["Domestic", ""]
  //   }
  // ];
  airportDisplayList: any = [
    { city: "Agra", airport: "Agra Airport" },
    {
      city: "Ahmedabad",
      airport: "Sardar Vallabhbhai Patel International Airport",
    },
    {
      city: "Amritsar",
      airport: "Sri Guru Ram Dass Jee International Airport",
    },
    // { city: "Aurangabad", airport: "Aurangabad Airport" },
    { city: "Bangalore", airport: "Kempegowda International Airport" },
    // { city: "Baroda", airport: "Airport Harni " },
    // { city: "Bhopal", airport: "Raja Bhoj Airport" },
    { city: "Bhubaneswar", airport: "Biju Patnaik International Airport" },
    { city: "Chandigarh", airport: "Chandigarh International Airport" },
    { city: "Chennai", airport: "Chennai International Airport" },
    // { city: "Cochin", airport: "Cochin International Airport" },
    { city: "Coimbatore", airport: "Coimbatore International Airport" },
    { city: "Dehradun", airport: "Jolly Grant Airport" },
    // { city: "Delhi/NCR", airport: "Indira Gandhi International Airport" },
    // {
    //   city: "Guwahati",
    //   airport: "Lokpriya Gopinath Bordoloi International Airport",
    // },
    // { city: "Gwalior", airport: "The Gwalior Airport" },
    { city: "Hyderabad", airport: "Rajiv Gandhi International Airport " },
    // { city: "Indore", airport: "Devi Ahilya Bai Holkar Airport" },
    { city: "Jaipur", airport: "Jaipur International Airport " },
    { city: "Jammu", airport: "Jammu Airport" },
    // { city: "Jamshedpur", airport: "Sonari Airport" },
    { city: "Jodhpur", airport: "Jodhpur Airport" },
    { city: "Kochi", airport: "Cochin International Airport" },
    {
      city: "Kolkata",
      airport: "Netaji Subhas Chandra Bose International Airport",
    },
    // { city: "Leh", airport: "Kushok Bakula Rimpochee Airport" },
    {
      city: "Lucknow",
      airport: "Chaudhary Charan Singh International Airport",
    },
    { city: "Madurai", airport: "Madurai Airport" },
    // { city: "Mangalore", airport: "Mangalore International Airport " },
    {
      city: "Mumbai",
      airport: "Chhatrapati Shivaji Maharaj International Airport",
    },
    { city: "Mysore", airport: "Mysore Airport" },
    // { city: "Nagpur", airport: "Dr. Babasaheb Ambedkar International Airport" },
    // { city: "Nasik", airport: "Nashik International Airport" },
    { city: "New Delhi", airport: "Indira Gandhi International Airport" },
    { city: "Patna", airport: "Jay Prakash Narayan Airport " },
    // { city: "Pondicherry", airport: "Puducherry Airport " },
    { city: "Pune", airport: "Pune International Airport" },
    // { city: "Raipur", airport: "Swami Vivekananda Airport" },
    // { city: "Ranchi", airport: "Birsa Munda Airport." },
    // { city: "Rishikesh", airport: "Jolly Grant airport " },
    // { city: "Shimla", airport: "Shimla Airport" },
    // { city: "Silliguri", airport: "Bagdogra Airport" },
    // { city: "Srinagar", airport: "Sheikh ul-Alam International Airport" },
    // { city: "Trichy", airport: "Tiruchirappalli International Airport" },
    // { city: "Trivandrum", airport: "Trivandrum International Airport" },
    { city: "Udaipur", airport: "Maharana Pratap Airport" },
    { city: "Vadodara", airport: "Vadodara Airport" },
    { city: "Varanasi", airport: "Lal Bahadur Shastri Airport" },
    // { city: "Vijayawada", airport: "Vijayawada International Airport" },
    { city: "Vishakhapatnam", airport: "Visakhapatnam Airport" },
  ];
  cardValidating: boolean = false;
  resetHeader: boolean = true;

  constructor(
    public dialog: MatDialog,
    private _commonService: CommonService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public data: DataSharingService,
    private loader: Loader,
    public meta: Meta,
    public pageTitle: Title
  ) {}

  ngOnInit() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const title = "Airport Transfer Service - Axis Bank Extraordinary Weekends";
    const des = "Book Airport Cabs - Axis Bank Extraordinary Weekends";
    this.pageTitle.setTitle(title);
    this.meta.updateTag({ name: "description", content: des });
    // this.airportDisplayList = this.groupBy(this.airportDisplayList, airportDisplay => airportDisplay.city)
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      setTimeout(() => {
        if (params.code == 1) {
          this.toastr.success(
            "Thank you for the travel details. You will be sent the service confirmation shortly."
          );
        } else if (params.code == 0) {
          this.toastr.warning(
            "Something went wrong! Please try again after sometime."
          );
        }
      });
    });
  }

  scroll(el) {
    let elm = document.getElementById(el);
    elm.scrollIntoView();
    window.scrollBy(0, -110);
  }

  cardbinValidate(bin_number) {
    this.cardValidating = true;
    if (bin_number.length == 8) {
      this._commonService.airportTransferBinValidate(bin_number).subscribe(
        (response) => {
          this.cardValidating = false;
          if (response.status != "error") {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
              airportList: this.airportList,
              bookings_count: response.bookings_count,
            };
            const dialogRef = this.dialog.open(
              DialogBookairportTransfers,
              dialogConfig
            );

            dialogRef.afterClosed().subscribe((result) => {});
          } else {
            this.toastr.error(response.msg);
          }
        },
        (err) => {
          this.cardValidating = false;
          this.toastr.warning(
            "An issue occured during validation, please try again later."
          );
        }
      );
    }
  }

  bookAirportVipPrivileges(experience): void {
    let user = null;
    if (JSON.parse(this._commonService.getUser())) {
      user = this._commonService.getUser();
      this.currentUser = JSON.parse(user);
      if (this.currentUser) {
        this.cardbinValidate(this.currentUser.bin);
      }
    } else {
      this.data.headerAuthenticated.emit(true);
      const dialogRef = this.dialog
        .open(AuthenticationComponent, {})
        .afterClosed()
        .subscribe((res) => {
          if (res === "true") {
            user = this._commonService.getUser();
            this.currentUser = JSON.parse(user);
            if (this.currentUser) {
              this.cardbinValidate(this.currentUser.bin);
            }
          }
        });
    }
    // var user = this._commonService.getUser();
    // if(user){

    // } else{
    //   this.toastr.error('Please login to continue.')
    //   this.resetHeader = false;
    //   setTimeout(()=>{
    //     this.resetHeader = true;
    //   },100)
    //   // $("#signup-form").modal('show');
    // };
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
}

@Component({
  selector: "dialog-bookairport-transfers",
  templateUrl: "./dialog-bookairport-transfers.html",
  styleUrls: ["./airport-transfers.component.scss"],
  providers: [
    CommonService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    GlobalConstants
  ],
})
export class DialogBookairportTransfers {
  golf_experience = false;
  showAirportTransferFields = true;
  showGuestCountvalidation = true;
  invalidBin = false;
  resErrorMessage = "";
  showError = false;
  emailPattern: any;
  mobilePattern: any;
  bookAirportTransfersForm: any;
  golfUpdate: any;
  currentUser: any;
  userData: any;
  guest_count_number: any;
  bookAirportVipPrivilegesError: string = "";
  submitButtonSubmitted: boolean = false;
  filetredAirports: any;
  selectedCity: any;
  events: string[] = [];
  filesList: number[] = [1];
  uploadForm: any;
  filesToUpload: Array<File> = [];
  guestList: number[] = [1, 2, 3];
  guestCount: number = 0;
  totalCount: number = 0;
  airportName: any;
  travelType: string[] = ["Pick-Up", "Drop"];
  vehicleType: string[] = ["Sedan (Swift Desire, Renault Logan, Toyota Etios)"];
  makingEnquiry: boolean = false;
  //fileData: FormData = new FormData();

  airportList: any = [];
  apList: any;


  dateFilter = (date: Date): boolean => {
    let dateNow = _moment().add(this.globalConstants.AIRPORT_TRANSFER_ALLOWED_BEFORE_DAYS, "days");
    return _moment(date) > dateNow;
  };
  guest_document_error_message: any;
  guest_document_error: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DialogBookairportTransfers>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private el: ElementRef,
    public globalConstants: GlobalConstants
  ) {
    this.emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.mobilePattern = /^\d{10}$/;

    this.bookAirportTransfersForm = this.fb.group({
      airport_vip_privileges_bin: ["", [Validators.required]],
      city: ["", [Validators.required]],
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      mobile_number: [
        "",
        [Validators.required, Validators.pattern(this.mobilePattern)],
      ],
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      booking_date: ["", Validators.required],
      booking_time_hour: ["", Validators.required],
      booking_time_minute: ["", Validators.required],
      travel_sector: ["", Validators.required],
      assistance_type: ["", Validators.required],
      airport: ["", Validators.required],
      bookingForGuest: [""],
      address: ["", Validators.required],
      passport: [""],
      guest_count: [""],
      guest_name_1: [""],
      guest_passport_1: [""],
      guest_name_2: [""],
      guest_passport_2: [""],
      guest_name_3: [""],
      guest_passport_3: [""],
      guest_document: [""],
      agree_terms: ["", Validators.required],
      contact_info: ["", Validators.required],
      traveller_count: ["", Validators.required],
      flight_number: ["", Validators.required],
      special_request: [""],
    });

    let user = this._commonService.getUser();
    if (user) {
      this.currentUser = JSON.parse(user);
      if (this.currentUser.full_name && this.currentUser.full_name.length > 0) {
        let nameArr = this.currentUser.full_name.split(" ");
        if (nameArr[0]) {
          this.bookAirportTransfersForm.controls.first_name.setValue(
            nameArr[0]
          );
          this.bookAirportTransfersForm.controls.first_name.disable();
        }
        if (nameArr && nameArr.length > 1) {
          this.bookAirportTransfersForm.controls.last_name.setValue(
            nameArr[nameArr.length - 1]
          );
          this.bookAirportTransfersForm.controls.last_name.disable();
        }
      }
      this.bookAirportTransfersForm.controls["mobile_number"].setValue(
        this.currentUser.mobile
      );
      this.bookAirportTransfersForm.controls["email"].setValue(
        this.currentUser.email
      );
      this.bookAirportTransfersForm.controls[
        "airport_vip_privileges_bin"
      ].setValue(this.currentUser.bin);
    }
    if (this.data?.airportList) {
      this.airportList = this.data.airportList;
    }
    this.setUserCategoryValidators();
  }

  get airport_vip_privileges_bin() {
    return this.bookAirportTransfersForm.get("airport_vip_privileges_bin");
  }
  get city() {
    return this.bookAirportTransfersForm.get("city");
  }
  get first_name() {
    return this.bookAirportTransfersForm.get("first_name");
  }
  get last_name() {
    return this.bookAirportTransfersForm.get("last_name");
  }
  get mobile_number() {
    return this.bookAirportTransfersForm.get("mobile_number");
  }
  get email() {
    return this.bookAirportTransfersForm.get("email");
  }
  get booking_date() {
    return this.bookAirportTransfersForm.get("booking_date");
  }
  get booking_time_hour() {
    return this.bookAirportTransfersForm.get("booking_time_hour");
  }
  get booking_time_minute() {
    return this.bookAirportTransfersForm.get("booking_time_minute");
  }
  get travel_sector() {
    return this.bookAirportTransfersForm.get("travel_sector");
  }
  get assistance_type() {
    return this.bookAirportTransfersForm.get("assistance_type");
  }
  get airport() {
    return this.bookAirportTransfersForm.get("airport");
  }
  get bookingForGuest() {
    return this.bookAirportTransfersForm.get("bookingForGuest");
  }
  get guest_count() {
    return this.bookAirportTransfersForm.get("guest_count");
  }
  get guest_name_1() {
    return this.bookAirportTransfersForm.get("guest_name_1");
  }
  get guest_name_2() {
    return this.bookAirportTransfersForm.get("guest_name_2");
  }
  get guest_name_3() {
    return this.bookAirportTransfersForm.get("guest_name_3");
  }
  get guest_passport_1() {
    return this.bookAirportTransfersForm.get("guest_passport_1");
  }
  get guest_passport_2() {
    return this.bookAirportTransfersForm.get("guest_passport_2");
  }
  get guest_passport_3() {
    return this.bookAirportTransfersForm.get("guest_passport_3");
  }
  get passport() {
    return this.bookAirportTransfersForm.get("passport");
  }
  get guest_document() {
    return this.bookAirportTransfersForm.get("guest_document");
  }
  get agree_terms() {
    return this.bookAirportTransfersForm.get("agree_terms");
  }
  get contact_info() {
    return this.bookAirportTransfersForm.get("contact_info");
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  sendPayuRequest(payment_create_response) {
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", payment_create_response.payu_url);

    for (var key in payment_create_response.payu_body) {
      if (payment_create_response.payu_body.hasOwnProperty(key)) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute(
          "value",
          payment_create_response.payu_body[key]
        );
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  set_guest_count(num) {
    const travel_sector = this.bookAirportTransfersForm.value.travel_sector;
    const guest_name_1 = this.bookAirportTransfersForm.get("guest_name_1");
    const guest_name_2 = this.bookAirportTransfersForm.get("guest_name_2");
    const guest_name_3 = this.bookAirportTransfersForm.get("guest_name_3");
    const guest_passport_1 =
      this.bookAirportTransfersForm.get("guest_passport_1");
    const guest_passport_2 =
      this.bookAirportTransfersForm.get("guest_passport_2");
    const guest_passport_3 =
      this.bookAirportTransfersForm.get("guest_passport_3");
    if (num == 1) {
      guest_name_1.setValidators([Validators.required]);
      this.guest_count.value = num;
      if (
        this.bookAirportTransfersForm.value.travel_sector == "International"
      ) {
        guest_passport_1.setValidators([Validators.required]);
      }
    } else {
      guest_name_1.clearValidators();
      guest_name_1.updateValueAndValidity();
      guest_passport_1.clearValidators();
      guest_passport_1.updateValueAndValidity();
      guest_name_2.clearValidators();
      guest_name_2.updateValueAndValidity();
      guest_passport_2.clearValidators();
      guest_passport_2.updateValueAndValidity();
      guest_name_3.clearValidators();
      guest_name_3.updateValueAndValidity();
      guest_passport_3.clearValidators();
      guest_passport_3.updateValueAndValidity();
    }
    this.guest_count_number = num;
    this.filesList.push(num);
    if (num == 0) {
      this.filesList = [1];
      this.bookAirportTransfersForm.get("guest_count").value = 0;
    }
  }

  change_guest_count() {
    let guest_num = this.guest_count.value;
    const travel_sector = this.bookAirportTransfersForm.value.travel_sector;
    if (
      this.bookAirportTransfersForm &&
      this.bookAirportTransfersForm.value &&
      this.bookAirportTransfersForm.value.guest_count
    ) {
      this.guest_count_number = this.bookAirportTransfersForm.value.guest_count;
      const guest_name_1 = this.bookAirportTransfersForm.get("guest_name_1");
      const guest_name_2 = this.bookAirportTransfersForm.get("guest_name_2");
      const guest_name_3 = this.bookAirportTransfersForm.get("guest_name_3");
      const guest_passport_1 =
        this.bookAirportTransfersForm.get("guest_passport_1");
      const guest_passport_2 =
        this.bookAirportTransfersForm.get("guest_passport_2");
      const guest_passport_3 =
        this.bookAirportTransfersForm.get("guest_passport_3");
      if (guest_num == 1) {
        guest_name_1.setValidators([Validators.required]);
        if (travel_sector == "International") {
          guest_passport_1.setValidators([Validators.required]);
        }
        guest_name_2.clearValidators();
        guest_name_2.updateValueAndValidity();
        guest_passport_2.clearValidators();
        guest_passport_2.updateValueAndValidity();
        guest_name_3.clearValidators();
        guest_name_3.updateValueAndValidity();
        guest_passport_3.clearValidators();
        guest_passport_3.updateValueAndValidity();
      }
      if (guest_num == 2) {
        guest_name_1.setValidators([Validators.required]);
        guest_name_2.setValidators([Validators.required]);
        if (travel_sector == "International") {
          guest_passport_1.setValidators([Validators.required]);
          guest_passport_2.setValidators([Validators.required]);
        }
        guest_name_3.clearValidators();
        guest_passport_3.clearValidators();
        guest_name_3.updateValueAndValidity();
        guest_passport_3.updateValueAndValidity();
      }
      if (guest_num == 3) {
        guest_name_1.setValidators([Validators.required]);
        guest_name_2.setValidators([Validators.required]);
        guest_name_3.setValidators([Validators.required]);
        if (travel_sector == "International") {
          guest_passport_1.setValidators([Validators.required]);
          guest_passport_2.setValidators([Validators.required]);
          guest_passport_3.setValidators([Validators.required]);
        }
      }
    } else {
      this.guest_count_number = 0;
    }
    for (let i = 1; i < guest_num; i++) {
      this.filesList.push(i);
    }
  }

  bookAirportTransfer() {
    let headers = new HttpHeaders();
    const files: Array<File> = this.filesToUpload;
    headers.set("Content-Type", null);
    headers.set("Accept", "multipart/form-data");
    let params = new HttpParams();
    const formData: FormData = new FormData();
    this.submitButtonSubmitted = true;
    this.bookAirportVipPrivilegesError = "";
    this.bookingTime();
    if (this.bookAirportTransfersForm.valid) {
      formData.append(
        "bin_used",
        this.bookAirportTransfersForm.value.airport_vip_privileges_bin
      );
      formData.append("city", this.bookAirportTransfersForm.value.city);
      formData.append(
        "first_name",
        this.bookAirportTransfersForm.value.first_name ??
          this.bookAirportTransfersForm.controls.first_name.value
      );
      formData.append(
        "last_name",
        this.bookAirportTransfersForm.value.last_name ??
          this.bookAirportTransfersForm.controls.last_name.value
      );
      formData.append("email", this.currentUser.email);
      formData.append("mobile_number", this.currentUser.mobile);
      formData.append(
        "booking_date_time",
        moment(
          this.bookAirportTransfersForm.value.booking_date.format(
            "DD/MM/YYYY"
          ) +
            " " +
            this.bookAirportTransfersForm.value.booking_time_hour +
            " " +
            this.bookAirportTransfersForm.value.booking_time_minute,
          "DD/MM/YYYY HH:mm a"
        ).format("ddd, D MMM YYYY h:mm:ss a")
      );
      formData.append(
        "travel_sector",
        this.bookAirportTransfersForm.value.travel_sector
      );
      formData.append(
        "assistance_type",
        this.bookAirportTransfersForm.value.assistance_type
      );
      formData.append("airport", this.bookAirportTransfersForm.value.airport);
      formData.append(
        "traveller_count",
        this.bookAirportTransfersForm.value.traveller_count
      );
      formData.append(
        "flight_number",
        this.bookAirportTransfersForm.value.flight_number
      );
      formData.append(
        "special_request",
        this.bookAirportTransfersForm.value.special_request
      );
      formData.append("passport", this.bookAirportTransfersForm.value.passport);
      formData.append("address", this.bookAirportTransfersForm.value.address);
      formData.append(
        "agree_terms",
        this.bookAirportTransfersForm.value.agree_terms
      );
      formData.append(
        "contact_info",
        this.bookAirportTransfersForm.value.contact_info
      );
      this.makingEnquiry = true;
      this._commonService.airportTransferUpdate(formData).subscribe(
        (response) => {
          if (response.status == "error") {
            if (
              Object.values(response.msg) &&
              Object.values(response.msg).length > 0 &&
              (Object.values(response.msg) || []).length > 0 &&
              typeof Object.values(response.msg)[0][0] == "string"
            ) {
              this.toastr.warning(response.msg[0][0]);
            } else {
              this.toastr.warning(
                "Please make sure you have entered valid information"
              );
            }
          } else {
            this.makingEnquiry = false;
            this.router.navigate([
              "airport-transfers",
              response.data.data.id,
              "checkout",
            ]);
            this.dialogRef.close();
          }
        },
        (err) => {
          this.bookAirportVipPrivilegesError = "";
          this.makingEnquiry = false;
          if (err && typeof err == "object" && err.json().booking_date) {
            this.bookAirportVipPrivilegesError = err
              .json()
              .booking_date.join(",");
          }
          if (
            this.bookAirportVipPrivilegesError == "" ||
            this.bookAirportVipPrivilegesError == undefined
          ) {
            this.bookAirportVipPrivilegesError =
              "Something went wrong! Please try again after sometime.";
          }
          this.toastr.warning(this.bookAirportVipPrivilegesError);
        }
      );
    } else {
      for (const key of Object.keys(this.bookAirportTransfersForm.controls)) {
        if (this.bookAirportTransfersForm.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]'
          );
          invalidControl.focus();
          break;
        }
      }
    }
  }

  upload(event: any) {
    // //let files = event.target.files;
    // const formData: any = new FormData();
    // const files: Array<File> = event.target.files;
    // //check file is valid
    for (var i = 0; i < event.target.files.length; i++) {
      if (!this.validateFile(event.target.files[i].name)) {
        console.log("Selected file format is not supported");
        this.guest_document_error = true;
        this.guest_document_error_message =
          "Selected file format is not supported, Please upload JPG, JPEG, PNG or PDF";
        return false;
      }
    }
    this.filesToUpload = <Array<File>>event.target.files;

    // if (event.target.files.length > 0) {
    //   //const file = event.target.files[0];
    //   this.uploadForm = event.target.files[0];
    //   for (var i = 0; i < files.length; i++) {
    //     this.uploadForm.append("file", files[i] )
    //   }
    // }

    // let fData: FormData = new FormData;

    // for (var i = 0; i < files.length; i++) {
    //     fData.append("file", files[i]);
    // }

    // this.fileData = event.target.files[0];
    // this.fileDataName = this.fileData.name;

    // var _data = {
    //     filename: 'Airport Vip Privileges File' + "",
    //     id: '0001'
    // }

    // fData.append("data", JSON.stringify(_data));
    // this._service.uploadFile(fData).subscribe(
    //     response => console.log(response),
    //     error => console.log(error)
    // )
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf(".") + 1);
    if (
      ext.toLowerCase() == "jpg" ||
      ext.toLowerCase() == "pdf" ||
      ext.toLowerCase() == "jpeg" ||
      ext.toLowerCase() == "png"
    ) {
      return true;
    } else {
      return false;
    }
  }

  getCityList() {
    return this.airportList
      .map((x) => x.city)
      .filter((x, i, a) => x && a.indexOf(x) === i);
  }

  filterAirport() {
    let data = this.airportList;
    if (this.bookAirportTransfersForm.value.city) {
      data = data.filter(
        (x) => x.city == this.bookAirportTransfersForm.value.city
      );
    }

    if (this.bookAirportTransfersForm.value.travel_sector == "Domestic") {
      data = data.filter((x) => x.domestic === "Y");
    } else if (
      this.bookAirportTransfersForm.value.travel_sector == "International"
    ) {
      data = data.filter((x) => x.international === "Y");
    } else {
      data = data;
    }
    if (this.bookAirportTransfersForm.value.assistance_type == "Departure") {
      data = data.filter((x) => x.departure == "Y");
    } else if (
      this.bookAirportTransfersForm.value.assistance_type == "Arrival"
    ) {
      data = data.filter((x) => x.arrival == "Y");
    } else {
      data = data;
    }

    if (data.length == 1) {
      this.bookAirportTransfersForm.controls["airport"].setValue(
        data[0].airport
      );
    }
    // if(!this.bookAirportTransfersForm.value.airport){
    //   this.bookAirportTransfersForm.controls['airport'].setValue(data[0].airport);
    // } else if (data.filter((x) => { return x.airport == this.bookAirportTransfersForm.value.airport }).length > 0){
    //   this.bookAirportTransfersForm.controls['airport'].setValue(data[0].airport);
    // } else if (data.filter((x) => { return x.airport == this.bookAirportTransfersForm.value.airport }).length == 0){
    //   this.bookAirportTransfersForm.controls['airport'].setValue(data[0].airport);
    // }
    return data;
  }

  filterHourTimeList() {
    let min_available_date = moment().add(2, "days");
    var current_booking_date = this.booking_date.value;
    var fullList = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ];

    if (current_booking_date > min_available_date) {
      return fullList;
    } else {
      let current_hour = min_available_date.get("hours");
      var arr_hour = fullList.filter((x) => {
        return x >= current_hour;
      });
      return arr_hour;
    }
  }

  filterMinutesTimeList() {
    var minList = [0, 15, 30, 45];
    let available_date = moment();
    let min_available_date = moment().add(2, "days");
    var current_booking_date = this.booking_date.value;
    let current_min = available_date.get("minutes");
    let current_hour = available_date.get("hours");
    var booking_time = this.booking_time_hour.value;
    if (
      booking_time > current_hour ||
      current_booking_date > min_available_date
    ) {
      return minList;
    } else {
      var arr_minutes = minList.filter((x) => {
        return x > current_min;
      });
      return arr_minutes;
    }
  }

  setUserCategoryValidators() {
    const travel_sector = this.bookAirportTransfersForm.get("travel_sector");
    const passport = this.bookAirportTransfersForm.get("passport");
    if (travel_sector.value === "International") {
      passport.setValidators([Validators.required]);
    } else {
      passport.setValidators(null);
    }
    passport.updateValueAndValidity();
  }

  setCityAirportValidators(selectedValue) {
    // this.apList =  this.airportList.find(x => x.city === selectedValue).airport;
    this.airportName = this.filterAirport()[0][0];
    this.selectedCity = selectedValue;
  }

  filetrTime(event, time) {
    console.log(time.target.value);
  }

  bookingTime() {
    let time = moment();
    time.set({
      hour: this.booking_time_hour.value,
      minute: this.booking_time_minute.value,
    });

    return time.format("hh:mm A");
  }

  getExpiration() {
    var expiration = localStorage.getItem("expires_at");
    var expiresAt = JSON.parse(expiration);
    return moment(new Date(expiresAt));
  }
}
